@tailwind utilities;

.letterboard {
  @apply border-[0.5px] border-bnft-dark-gray font-bold overflow-hidden uppercase;
  @apply text-2xl leading-loose tracking-[.25em] md:text-6xl;
  font-family: Founders Grotesk, Arial Black, sans-serif;
  color: #95cd41;
  text-shadow: 1px 1px #666, 3px 3px #000;
}
.default-letterboard {
  @apply letterboard;
  @apply bg-bnft-orange;
}

@layer utilities {
  .text-input {
    @apply text-bnft-dark-gray mt-0 p-2 rounded border-0 border-b focus:ring-0 focus:border-white disabled:bg-transparent disabled:text-bnft-dark-gray disabled:border disabled:shadow-none;
  }

  .text-input-fw {
    @apply text-input;
    @apply w-full;
  }

  .btn {
    @apply py-2 px-3 rounded-md font-medium text-white transition ease-in duration-200 shadow-md;
  }

  .btn-action {
    @apply btn;
    @apply bg-blue-600 hover:bg-blue-800 focus:ring-blue-500 focus:ring-offset-blue-200;
  }

  .btn-enabled {
    @apply btn;
    @apply bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200;
  }

  .btn-disabled {
    @apply btn;
    @apply bg-gray-500 hover:bg-gray-500 focus:ring-gray-300 focus:ring-offset-gray-200;
  }

  .btn-cancel {
    @apply btn;
    @apply bg-red-700 hover:bg-red-800 focus:ring-red-300 focus:ring-offset-red-200;
  }

  .link {
    @apply inline-block text-black text-[0.4rem] p-1 md:text-base md:p-2 hover:bg-bnft-blue-300 rounded-md;
  }
  .link-underline {
    @apply text-bnft-dark-gray hover:text-bnft-blue-300;
  }
  .link-current {
    @apply link;
    @apply bg-bnft-yellow;
  }
  .link-with-bg {
    @apply link;
    @apply bg-bnft-blue-100;
  }
}
