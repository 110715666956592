@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .base-px {
    @apply px-4 md:px-60;
    font-family: Founders Grotesk, sans-serif;
  }
  .page-title {
    @apply uppercase text-2xl md:text-5xl;
    font-family: Founders Grotesk, Arial Black, sans-serif;
  }
  .page-subtitle {
    @apply py-2 text-base md:text-xl;
  }
}
